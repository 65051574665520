<template>
  <div>
    <h1 class="font-weight-regular text-center light-green--text lighten-3--text pb-5" >{{$root.appName}}</h1>
    <v-card style="width: 100%" max-width="500" class="pa-4">
      <v-form>
        <v-card-title class="title font-weight-regular justify-center">
          <span>{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-alert
              v-if="this.$store.getters['auth/status'] == 'error'"
              color="red darken-1"
              dark
            >
              {{ $t(this.$store.getters["auth/error"]) }}
            </v-alert>
            <a-form-from-model
              v-model="data"
              :model="model"
              :errors="errors"
              @validate="validate($event)"
            ></a-form-from-model>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn type="submit" @click.prevent="submit()">
            {{ $t("Login") }}
          </v-btn>
          <!-- <v-btn :to="{ name: 'registration' }">
          {{ $t("Registration") }}
        </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import validate from "@/share/components/libs/validate";
import validators from "@/share/components/libs/validators";
import fillForm from "@/share/components/libs/fillForm";

const model = [
  {
    name: "login",
    title: "Login",
    validator: ["req"],
    type: "string",
  },
  {
    name: "password",
    title: "Password",
    type: "password",
    validator: ["req"],
  },
];

export default {
  mixins: [validate, validators, fillForm],
  data() {
    return {
      title: "Login",
      model: model,
    };
  },
  created() {
    document.title = this.title;
    this.fillForm();
  },
  methods: {
    submit: async function () {
      if (await this.validateAll(this.data)) {
        this.login();
      }
    },
    login: function () {
      this.$store
        .dispatch("auth/login", {
          login: this.data.login,
          password: this.data.password,
        })
        .then(() => {
          this.$router.push("/");
        })
        .catch((e) => {
          this.error = e;
        });
    },
  },
};
</script>

